import React from "react";

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  mode?: "info" | "danger" | "warning" | "success";
  forwardRef?: React.ForwardedRef<HTMLDivElement>;
}

const Alert = ({
  mode = "info",
  style,
  className,
  children,
  forwardRef,
  ...rest
}: Props) => (
  <div
    ref={forwardRef}
    style={style}
    className={`alert alert-${mode} ${className || ""}`}
    {...rest}
  >
    {children}
  </div>
);

export default React.forwardRef<HTMLDivElement, Props>((props, ref) => (
  <Alert forwardRef={ref} {...props} />
));
